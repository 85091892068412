import { useState, ChangeEvent, FormEvent } from 'react';
import { useParams } from 'wouter';
import axios from 'axios';
import useCsrfToken from '../../hooks/UseCsrfToken';
import handleAxiosError from '../../components/error/AxiosError';
import ErrorDisplay from '../../components/error/ErrorDisplay';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface FormData {
    password: string;
    confirm_password: string;
}

function ResetPasswordForm() {
    const [formData, setFormData] = useState<FormData>({
        password: '',
        confirm_password: '',
    });
    const params = useParams<{ token: string }>();
    const { token } = params;
    const [errors, setErrors] = useState<Record<string, string[]>>({});
    const [successMessage, setSuccessMessage] = useState<string>('');
    const csrfToken = useCsrfToken();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    
    const errorPlaceholder = "errorPlaceHolder min-h-[24px]";


    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({});
        setSuccessMessage('');
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (formData.password !== formData.confirm_password) {
            setErrors({ ...errors, confirm_password: ['Password and confirm password do not match.'] });
            return; 
        }
    
        try {
            const response = await axios.post(`/api/reset_password/${token}`, {
                password: formData.password, confirm_password: formData.confirm_password
            }, {
                headers: {
                    'X-CSRFToken': csrfToken,
                },
            });

            
            setSuccessMessage(response.data.message);
            setFormData({
                password: '',
                confirm_password: '',
            });
        } catch (error) {
            const processedErrors = handleAxiosError(error);
            setErrors(processedErrors);
            console.error('There was an error:', processedErrors);
        }
    };
    
    return (
        <main className="flex text-left flex-col justify-center items-center h-screen space-y-4" role="main">
            <div className="w-full max-w-md mx-auto">
                {/* Success message with improved visibility and styling */}
                <div className={errorPlaceholder}>
                    {successMessage && <div className="text-center text-green-500 font-medium p-2 rounded bg-green-100">{successMessage}</div>}
                </div>
                <form onSubmit={handleSubmit} className="space-y-6 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <header className="mb-8 text-left">
                        {/* Suggestion for a more descriptive comment about the logo */}
                        <img src="/static/adamkq_logo.png" alt="adamkq" className="mx-auto h-12 w-auto ml-0" />
                        <h2 className="text-2xl font-bold text-gray-900 mb-2">Reset Your Password</h2>
                        <p className="text-gray-600">Please enter and confirm your new password below.</p>
                    </header>
    
                    <div className="flex flex-col relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="New Password"
                            value={formData.password}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline pr-10"
                            required
                        />
                        <button
                            type="button"
                            className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-500"
                            onClick={() => setShowPassword(!showPassword)}
                            aria-label={showPassword ? "Hide password" : "Show password"}
                        >
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </button>
                    </div>
    
                    <div className="flex flex-col relative">
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirm_password"
                            placeholder="Confirm Password"
                            value={formData.confirm_password}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline pr-10"
                            required
                        />
                        <button
                            type="button"
                            className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-500"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            aria-label={showConfirmPassword ? "Hide confirmation password" : "Show confirmation password"}
                        >
                            <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                        </button>
                    </div>
    
                    <div className={errorPlaceholder}>
                        <ErrorDisplay errors={errors}/>
                    </div>
    
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">Reset Password</button>
                </form>
            </div>
        </main>
    );    
}

export default ResetPasswordForm;
