import React, { useEffect, useState, useRef, useMemo } from 'react';

const TypingAnimation: React.FC = () => {
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [index, setIndex] = useState(0);
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [isInView, setIsInView] = useState(false);
  
  const phrases = useMemo(() => [
    "I'm Adam.", 
    "I am someone ...\nwho is enthusiastic about \ncreating a bridge between\nman and machine.",
    "我是亚当", 
    "我是一个 \n热衷于在人与机器之间 \n 的桥梁的人",
    "Je suis Adam", 
    "Je suis quelqu'un ... \nqui est enthousiaste à l'idée \n de créer un pont entre \nl'homme et la machine",
    "Я Адам", 
    "Я человек... \nкто с энтузиазмом относится \nк созданию моста между \n человеком и машиной",
    "僕はアダムだ",
    "私は誰か．\n 私は...人間と機械の架け橋になることに熱狂している人間です。\n人と機械の架け橋を作ることに熱中している人です"
  ], []);
  

  const typingRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio > 0) {
          setIsInView(true);
        } else {
          setIsInView(false);
        }
      },
      { threshold: 0.1 }
    );
  
    const currentElement = typingRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }
  
    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);
  
  useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout | undefined;

    if (isInView) {
      let typeSpeed = 100;
      let deleteSpeed = 50;
      let currentPhrase = phrases[currentPhraseIndex];

      if (isDeleting) {
        if (index > 0) {
          timeoutId = setTimeout(() => {
            setText((prev) => prev.slice(0, prev.length - 1));
            setIndex((prev) => prev - 1);
          }, deleteSpeed);
        } else {
          setCurrentPhraseIndex((prev) => (prev + 1) % phrases.length);
          setIsDeleting(false);
        }
      } else {
        if (index < currentPhrase.length) {
          timeoutId = setTimeout(() => {
            setText((prev) => prev + currentPhrase.charAt(index));
            setIndex((prev) => prev + 1);
          }, typeSpeed);
        } else {
          timeoutId = setTimeout(() => {
            setIsDeleting(true);
          }, 2000);
        }
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [text, isDeleting, index, currentPhraseIndex, isInView, phrases]);

  const renderedText = text.split('\n').map((item, idx, arr) => (
    <React.Fragment key={idx}>
      {item}
      {idx < arr.length - 1 && <br />}
    </React.Fragment>
  ));

  return (
    <div 
      ref={typingRef} 
      className="typing-animation text-white drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] font-mono whitespace-pre-wrap box-border max-w-full overflow-hidden text-lg sm:text-xl md:text-2xl lg:text-3xl"
      style={{ 
        fontSize: 'clamp(1rem, 3vw, 2rem)',
        height: '10rem', // Set a fixed height to prevent pushing content
      }}
    >
      <span>{renderedText}<span className="cursor">|</span></span>
    </div>
  );
};

export default TypingAnimation;
