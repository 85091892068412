import React, { useState } from 'react';
import { useRoute } from 'wouter';
import axios from 'axios';
import handleAxiosError from '../components/error/AxiosError';

const EmailConfirm = () => {
  const [, params] = useRoute("/emailConfirm/:userEmail"); 
  const userEmail = params?.userEmail ? decodeURIComponent(params.userEmail) : '';
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const [loading, setLoading] = useState(false);

  const resendConfirmationEmail = async () => {
    if (!userEmail) {
      setErrors({ general: ["No email address provided."] });
      return;
    }

    setLoading(true);
    try {
      await axios.get(`/api/resend-confirmation/${userEmail}`);
      setErrors({}); 
    } catch (err) {
      const normalizedErrors = handleAxiosError(err);
      setErrors(normalizedErrors);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
      <div className="text-center p-10 bg-white rounded-xl shadow-xl max-w-lg">
        <h2 className="text-4xl font-bold text-gray-900 mb-8">Thank You! 🎉</h2>
        <p className="mb-8 text-gray-700">A confirmation email has been sent to <strong className="text-gray-900">{userEmail}</strong>.</p>
        {errors.general && errors.general.map((error, index) => (
          <p key={index} className="text-red-600 mb-4">{error}</p>
        ))}
        <div className="flex flex-col items-center">
          {!loading && (
            <>
              <p className="text-gray-600 mb-4">Didn't receive the email? No worries, let's try again!</p>
              <button
                className="bg-blue-600 hover:bg-blue-800 text-white font-semibold py-2 px-8 rounded-full focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
                onClick={resendConfirmationEmail}
              >
                Resend Email
              </button>
            </>
          )}
          {loading && (
            <p className="mt-4 text-blue-500">Sending email...</p>
          )}
        </div>
      </div>
    </div>
  );
  
};

export default EmailConfirm;
