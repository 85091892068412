import React, { createContext, useContext, useState, ReactNode } from 'react';

interface Notification {
  title: string;
  color: string; 
  content?: string; 
}

interface NotificationContextType {
  notify: (title: string, color: string, content?: string,) => void;
}

interface NotificationProviderProps {
  children: ReactNode;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (!context) {
      throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
  };
  
  export const NotificationProvider = ({ children }: NotificationProviderProps) => {
    const [notification, setNotification] = useState<Notification | null>(null);
  
    const notify = (title: string, color: string, content: string = '') => {
        setNotification({ title, content, color });
        setTimeout(() => setNotification(null), 10000); // Auto-close after 3 seconds
      };
      
  
    const handleClose = () => {
      setNotification(null); // Close notification when "X" is clicked
    };
  
    return (
      <NotificationContext.Provider value={{ notify }}>
        {children}
        {notification && (
          <div className={`fixed top-7 left-1/2 transform -translate-x-1/2 p-4 rounded-lg shadow-lg text-white opacity-90 ${notification.color} flex justify-between items-center`} role="alert" style={{ zIndex: 1500, minWidth: '300px' }}>
            <div>
              <strong>{notification.title}</strong>
              {notification.content && <p>{notification.content}</p>}
            </div>
            <button onClick={handleClose} className="ml-4 text-lg font-semibold">×</button>
          </div>
        )}
      </NotificationContext.Provider>
    );
  };
  