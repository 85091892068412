import React, { useRef, useEffect, useMemo } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Mesh, MeshStandardMaterial, Group, Vector3 } from 'three';

const Model: React.FC = () => {
  const modelRef = useRef<Group | null>(null);
  const { scene, camera } = useThree();

  const gltfLoader = useMemo(() => new GLTFLoader(), []);

  useEffect(() => {
    const loadModel = async () => {
      try {
        const gltf = await gltfLoader.loadAsync('/static/models/iconModel.glb');
        const model = gltf.scene;
        model.rotation.x = Math.PI / 2;
        model.position.set(0, -1, -105);
        model.scale.set(0.7, 0.7, 0.7);
        model.traverse((child) => {
          if (child instanceof Mesh) {
            const mesh = child;
            mesh.material = new MeshStandardMaterial({
              color: 'white',
              transparent: true,
              opacity: 0.5,
            });
          }
        });
        modelRef.current = model;
        scene.add(model);
        camera.lookAt(new Vector3(0, 0, 0));
      } catch (error) {
        console.error('Error loading model:', error);
      }
    };

    loadModel();

    return () => {
      if (modelRef.current) {
        scene.remove(modelRef.current);
      }
    };
  }, [scene, camera, gltfLoader]);

  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.z += 0.01;
    }
  });

  return null;
};

const ModelViewer: React.FC = () => {
  return (
    <Canvas
      style={{
        position: 'absolute',
        top: 0,
        zIndex: -1,
        height: '100vh',
        width: '100vw',
      }}
      camera={{ position: [0, 0, 5], fov: 50 }}
      onCreated={({ gl }) => {
        gl.setClearColor('white');
        gl.setPixelRatio(window.devicePixelRatio);
      }}
    >
      <React.Suspense fallback={null}>
        <ambientLight intensity={1} />
        <directionalLight position={[0, 10, 5]} intensity={0.5} />
        <pointLight position={[-10, -10, 10]} intensity={0.5} />
        <Model />
      </React.Suspense>
    </Canvas>
  );
};

export default ModelViewer;