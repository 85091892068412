import React, { useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { Link } from 'wouter';

const downloadFile = (data: string, fileName: string, fileType: string) => {
  const blob = new Blob([data], { type: fileType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(url);
};

const DeepLinkSorter: React.FC = () => {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState<{ title: string; link: string }[]>([]);
  const [error, setError] = useState('');
  const [canConnect, setCanConnect] = useState(false);
  const socketRef = useRef<Socket | null>(null);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL || '';
    const namespace = '/deep-link-sorter';
    const socket: Socket = io(`${SOCKET_SERVER_URL}${namespace}`, {
      transports: ['websocket']
    });
    
    socketRef.current = socket;

    socket.on('connect', () => console.log(`Connected to namespace ${namespace}...!`));

    socket.on('response', (data: string) => {
      const parsedData = JSON.parse(data);
      setResponse(parsedData);
    });

    socket.on('error', (message: string) => {
      setError(message);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const checkConnectionLimit = async () => {
      try {
        const response = await axios.get('/deep-link-sorter/check_connection_limit');
        setCanConnect(response.data.can_connect);
      } catch (error) {
        console.error('Error checking connection limit:', error);
      }
    };

    checkConnectionLimit();
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (socketRef.current) {
      socketRef.current.emit('query', { query });
      setQuery('');
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="flex flex-col text-4xl justify-center items-center h-screen">
        <h1>
          Please <Link href="/login" className="text-blue-400 hover:text-blue-700">login</Link> to demo this project.
        </h1>
      </div>
    );
  }

  if (!canConnect) {
    return (
      <div className="flex flex-col text-4xl justify-center items-center h-screen">
        <h1>Sorry, the deep link sorter is currently at maximum capacity. Please try again later.</h1>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen p-4 mt-24">
      <h1 className="text-2xl font-bold mb-4">Deep Link Sorter</h1>
      <form onSubmit={handleSubmit} className="mb-4">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter your query"
          className="border border-gray-300 rounded px-2 py-1 mr-2"
        />
        <button type="submit" className="bg-blue-500 text-white rounded px-4 py-1">
          Submit
        </button>
      </form>
      {error && <div className="text-red-500 mb-4">{error}</div>}

      {response.length > 0 && (
        <ul className="list-disc pl-6 max-w-4xl">
          {response.map((item, index) => (
            <li key={index}>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      )}
      <div className="bg-orange-500 bg-opacity-60 rounded-lg text-left shadow-md p-4 max-w-4xl text-white">
        <p className='drop-shadow-[0_0_8px_rgba(0,0,0,0.2)]'>
          The script utilizes a range of Python libraries like torch, numpy, faiss, and BeautifulSoup. These are
          foundational tools in Python for handling tasks ranging from numerical computations and machine learning
          to web scraping and data parsing. This script demonstrates the technical usage of these libraries
          through webscrapping relevant subreddits by query given by a user.
        </p> <br/>
        <p className='drop-shadow-[0_0_8px_rgba(0,0,0,0.2)]'>
          The script begins by scraping Reddit, followed by filtering and processing posts. It then transforms these posts into embeddings using
          Sentence Transformers. Finally, posts are sorted with the help of FAISS, based on how closely
          they relate to predefined topics given by the user. However, since the post is limited to 10 Ive decreased the accuracy.
          This process encapsulates a complete data pipeline from data collection to processing and analysis, showcasing both
          advanced programming techniques and a practical application of AI.
        </p> <br/>
        <p className='drop-shadow-[0_0_8px_rgba(0,0,0,0.2)]'>
          In my <a className="text-blue-400 hover:text-blue-700" href="https://github.com/adamkarl02">github profile</a>, you can test it out by simply running
          the python file! But if you want a demo, you can
          simply enter your queries seperated by commas for more specific posts and enjoy! However, in this demo only the first 10 posts will be processed and output might vary.
        </p>
      </div>
    </div>
  );
};

export default DeepLinkSorter;