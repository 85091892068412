import { Route, Switch } from 'wouter';
import SignupForm from './pages/auth/SignupForm';
import LoginForm from './pages/auth/LoginForm';
import { AuthProvider } from './context/AuthContext'; // Adjust the path as necessary
import ForgotPasswordForm from './pages/password/ForgotPasswordForm';
import ConfirmationPage from './email/ConfirmationPage';
import ResendConfirmationEmail from './email/ResendConfirmationEmail';
import EmailConfirm from './email/EmailConfirm';
import ResetPasswordForm from './pages/password/ResetPasswordForm';
import LandingPage from './pages/LandingPage';
import Navigation from './components/common/Navigation';
import "./static/index.css";
import { NotificationProvider } from './components/common/Notification';
import FaceHandTracker from './pages/projects/FaceAndHandTracker';
import DeepLinkSorter from './pages/projects/DeepLinkSorter'; 
import Website from './pages/projects/Website'; 
import WeatherStation from './pages/projects/WeatherStation'; 
import Nattklar from './pages/projects/Nattklar'; 
import { ThemeProvider } from './context/ThemeContext';
import About from './pages/AboutMe';
import Contact from './pages/Contact';

const App = () => {
  
  return (
    <ThemeProvider>
    <NotificationProvider>
      <AuthProvider>
        <div className="App">
          <Navigation />
          <Switch>
              <Route path="/" component={LandingPage} />
              <Route path="/login" component={LoginForm} />
              <Route path="/signup" component={SignupForm} />
              <Route path="/forgot-password" component={ForgotPasswordForm} />
              <Route path="/confirm/:token" component={ConfirmationPage} />
              <Route path="/resend-confirmation/:userEmail" component={ResendConfirmationEmail} />
              <Route path="/reset-password/:token" component={ResetPasswordForm} />
              <Route path="/emailConfirm/:userEmail" component={EmailConfirm} />
              <Route path="/face-hand-tracker" component={FaceHandTracker} /> 
              <Route path="/deep-link-sorter" component={DeepLinkSorter} /> 
              <Route path="/website" component={Website} /> 
              <Route path="/weather-station" component={WeatherStation} /> 
              <Route path="/nattklar" component={Nattklar} /> 
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
          </Switch>
        </div>
      </AuthProvider>
    </NotificationProvider>
    </ThemeProvider>
  );
}

export default App;