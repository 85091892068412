import React, { useState, useEffect } from 'react';
import { Link } from 'wouter';
import { useAuth } from '../../context/AuthContext';
import { CSSTransition } from 'react-transition-group';
import '../../static/Navigation.css';

const Navigation: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAuthenticated, username, logout } = useAuth();

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMenuOpen]);

  const handleLogout = async () => {
    await logout();
    setIsMenuOpen(false);
  };

  const closeMenu = () => setIsMenuOpen(false);

  return (
    <nav className={`fixed top-0 left-[1.5%] mt-4 right-[1.5%] z-50 bg-transparent flex items-center justify-between p-4 ${isMenuOpen ? 'shadow-lg' : ''}`} aria-label="Main navigation">
      <div className="logo z-20">
        <Link href="/" onClick={closeMenu}>
          <img src="./static/adamkq_logo.png" alt="adamkq" className="h-16 text-3xl text-orange-500  cursor-pointer" />
        </Link>
      </div>
      <div className='z-10 fixed cursor-pointer'>
        <div className="nexus-container fixed sm:left-[40%] md:left-[45%] lg:left-[45%] xl:left-[45%] 2xl:left-[45%] -mt-12 cursor-pointer" >
          <svg className="nexus" xmlns="http://www.w3.org/2000/svg" viewBox="-100 0 500 500" shapeRendering="geometricPrecision" textRendering="geometricPrecision" width="200" height="200">
            <path className="nexus-path" d="M150,99.570815v151.072962" transform="translate(0 0.000001)" fill="none" stroke="white" strokeWidth="2" />
            <path className="nexus-path" d="" fill="none" stroke="white" />
            <path className="nexus-path" d="" fill="none" stroke="white" />
            <path className="nexus-path" d="M301.502146,250l-301.287554.643778" fill="none" stroke="white" strokeWidth="2" />
            <path className="nexus-path" d="M150,250.643778L279.184549,383.690987" fill="none" stroke="white" strokeWidth="2" />
            <path className="nexus-path" d="M150,250.643778L21.67382,383.690987" fill="none" stroke="white" strokeWidth="2" />
            <path className="nexus-path" d="" fill="none" stroke="white" />
          </svg>
        </div>
      </div>
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="menu-button text-2xl focus:outline-none z-20"
        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
      >
        {isMenuOpen ? 'Close' : 'Menu'}
      </button>
      <CSSTransition
        in={isMenuOpen}
        timeout={300}
        classNames="menu-animation"
        unmountOnExit
      >
        <div className="menu fixed top-0 left-0 right-0 bottom-0 flex flex-col items-start md:pl-20 lg:pl-96 z-10 select-none" aria-label="Navigation menu">
          <Link href="/" className="menu-item text-7xl sm:text-8xl md:text-9xl lg:text-9xl xl:text- 2xl:text-9xl mt-24" onClick={closeMenu}><span>HOME</span></Link>
          <Link href="/about" className="menu-item text-7xl sm:text-8xl md:text-9xl lg:text-9xl xl:text- 2xl:text-9xl -mt-6" onClick={closeMenu}><span>ABOUT</span></Link>
          <Link href="/contact" className="menu-item text-7xl sm:text-8xl md:text-9xl lg:text-9xl xl:text- 2xl:text-9xl -mt-6" onClick={closeMenu}><span>CONTACT</span></Link>
          {isAuthenticated ? (
            <div className="flex flex-col items-start">
              <span className="username text-7xl sm:text-8xl md:text-9xl lg:text-9xl xl:text- 2xl:text-9xl text-white-100 -mt-4">{username}</span>
              <button
                onClick={handleLogout}
                className="logout font-bold rounded focus:outline-none focus:shadow-outline text-7xl sm:text-8xl md:text-9xl lg:text-9xl xl:text- 2xl:text-9xl -mt-4"
              >
                <span>LOGOUT</span>
              </button>
            </div>
          ) : (
            <Link href="/login" className="menu-item text-7xl sm:text-8xl md:text-9xl lg:text-9xl xl:text- 2xl:text-9xl -mt-6" onClick={closeMenu}><span>LOGIN</span></Link>
          )}
          <a href="https://www.instagram.com/adamkarl8/" className="navLinks font-semibold text-xl mt-auto -mb-2 flex items-center" onClick={closeMenu}>
            INSTAGRAM
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" className="ml-1">
              <path d="M7 17L17 7M17 7H7M17 7V17" />
            </svg>
          </a>
          <a href="https://www.linkedin.com/in/adam-karl02/" className="navLinks font-semibold text-xl -mb-2 flex items-center" onClick={closeMenu}>
            LINKEDIN
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" className="ml-1">
              <path d="M7 17L17 7M17 7H7M17 7V17" />
            </svg>
          </a>
          <a href="https://discord.com/users/193097321813245952" className="navLinks font-semibold text-xl flex items-center" onClick={closeMenu}>
            DISCORD
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" className="ml-1">
              <path d="M7 17L17 7M17 7H7M17 7V17" />
            </svg>
          </a>
        </div>
      </CSSTransition>
    </nav>
  );
};

export default Navigation;
