import React from 'react';
import ContentBox from '../components/common/ContentBox';
import Footer from '../components/common/Footer';
import ExperienceBox from '../components/common/ExperienceBox';
import useScrollPosition from '../components/common/useScrollPosition';

const About: React.FC = () => {
    const scrollY = useScrollPosition();
    return (
        <div className="w-full bg-black min-h-dvh flex flex-col justify-center items-center overflow-hidden">
            <div className="flex flex-col md:flex-row items-center w-full h-full mt-16 md:mt-36">
                <div className="flex justify-center md:justify-start mt-8 md:mt-[22%] ml-0 md:ml-[20%]">
                    <div className="text-center md:text-right">
                        <h1 className="text-4xl md:text-8xl text-orange-500">Adam Karl</h1>
                        <h1 className="text-4xl md:text-8xl text-orange-500">Queja</h1>
                    </div>
                </div>
                <div className="w-[80%] md:w-[30%] h-auto flex justify-center p-4 mt-8 md:mt-0 md:mr-auto">
                    <img className="w-full md:w-auto h-auto max-h-full opacity-70" src="./static/image-profile.jpg" alt="Adam Karl" />
                </div>
            </div>
            <ContentBox
                img="./static/aboutImages/languages.png"
                title={["Languages"]}
                content={[
                    "Throughout high school, I learned HTML/CSS, JavaScript, and PHP.",
                    "During my university years, I have acquired proficiency in various programming",
                    "languages with Python being my favorite. Upon joining Portal Space,",
                    "I seized the amazing opportunity to delve into ARM assembly language."
                ]}
                number={1}
                link={""}
                fontColor='white'
                disableHover={true}
                scrollY={scrollY}
            />
            <ContentBox
                img="./static/aboutImages/devtools.png"
                title={["Dev Tools"]}
                content={[
                    "I have learned many tools to create what I imagine.",
                    "Many of these tools I learned through university and",
                    "some through my own projects."]}
                number={2}
                link={""}
                fontColor='white'
                disableHover={true}
                scrollY={scrollY}
            />
            <ContentBox
                img="./static/aboutImages/project.png"
                title={["Project", "Management"]}
                content={[
                    "Learning to work in a team is crucial for any",
                    "software developers. I have not only worked with",
                    "likeminded students at UiO but also at Portal",
                    "Space which allowed me to learn not only tools",
                    "but team management."
                ]}
                number={3}
                link={""}
                fontColor='white'
                disableHover={true}
                scrollY={scrollY}
            />

            <div className="experience-container text-left flex flex-col items-center mt-16 md:mt-[10%]">
                <h1 className='text-white text-4xl md:text-8xl mb-8 md:mb-[5%]'>Experience</h1>
                <ExperienceBox
                    logo={<img src="./static/companyLogos/portalSpace.png" alt="Portal Space Logo" />}
                    companyName="Portal Space"
                    startDate="Sep 2023"
                    endDate=""
                    jobTitles={[
                        {
                            title: 'Electrical Engineer',
                            startDate: 'Sep 2023',
                            endDate: '',
                            description: "In Portal Space, I've been into two projects - Big Brother and Weather Station.\nBig Brother is a surveillance system to surveil the rocket engine and to\n surveil the area for danger. Weather Station is a weather web app to \n ensure if certain days are launch ready status.",
                        },
                    ]}
                    color='white'
                />
                <ExperienceBox
                    logo={<img src="./static/companyLogos/ikea.jpeg" alt="Portal Space Logo" />}
                    companyName="IKEA"
                    startDate="June 2022"
                    endDate=""
                    jobTitles={[
                        {
                            title: 'Seller',
                            startDate: 'Jan 2024',
                            endDate: '',
                            description: 'As of right now, I am working as a seller in the lighting department where\nI work with an amazing team tackle on technical questions regarding \nlighting for their home..',
                        },
                        {
                            title: 'IKEA Food Worker',
                            startDate: 'June 2022',
                            endDate: 'Jan 2024',
                            description: 'During my time in IKEA Food. I have met amazing colleagues who have not \nonly developed me as a person but proffesionally as well. Here I learned how\nto handle stress and take responsibility not only for myself but for the team. ',
                        },
                    ]}
                    color='white'
                />
            </div>
            <div className="experience-container text-left flex flex-col items-center mt-8 md:mt-[1%]">
                <h1 className='text-white text-4xl md:text-8xl mb-8 md:mb-[5%]'>Education</h1>
                <ExperienceBox
                    logo={<img src="./static/companyLogos/uio.png" alt="Portal Space Logo" />}
                    companyName="University of Oslo"
                    startDate="August 2021"
                    endDate="July 2024"
                    jobTitles={[
                        {
                            title: 'Informatikk: programmering og systemarkitektur (bachelor)',
                            startDate: 'August 2021',
                            endDate: 'July 2024',
                            description: "I have learned many things from my time at UiO. Greate experiences and \nchallenging obstacles but as a result I have grown not only as a person\nbut as a software developer.",
                        },
                    ]}
                    color='white'
                />
            </div>
            <Footer />
        </div>
    );
};

export default About;