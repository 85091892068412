import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'wouter';
import { animated, useSpring } from '@react-spring/web';
import '../../static/ContentBox.css';

type ComponentProps = {
  img: string;
  title: string[];
  content: string[];
  number: number;
  link: string;
  fontColor?: string;
  disableHover?: boolean;
  scrollY: number; // Assuming scrollY is passed as a prop to track the vertical scroll position
};

const ContentBox: React.FC<ComponentProps> = ({
  img,
  title,
  content,
  number,
  link,
  fontColor = 'black',
  disableHover = false,
  scrollY,
}) => {
  const isEven = number % 2 === 0;
  const [titleHeight, setTitleHeight] = useState(0);
  const [color] = useState(fontColor);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const titleLineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (titleRef.current) {
      setTitleHeight(titleRef.current.clientHeight);
    }
  }, [title]);


  const calculateParallaxSpeeds = (scrollY: number): { titleSpeed: number; imageSpeed: number } => {
    const viewportWidth = window.innerWidth;

    // Define base speed multipliers
    let titleSpeedMultiplier = 65;
    let imageSpeedMultiplier = 20;

    if (viewportWidth <= 640) { // Tailwind's 'sm' breakpoint
      titleSpeedMultiplier = 450; // Slower movement for smaller screens
      imageSpeedMultiplier = 30; // Slower movement for images on smaller screens
    } else if (viewportWidth > 640 && viewportWidth < 768) { // 'sm' to 'md'
      titleSpeedMultiplier = 400; // Adjusting for medium screens
      imageSpeedMultiplier = 25;
    } else if (viewportWidth >= 768 && viewportWidth < 1024) { // 'md' to 'lg'
      titleSpeedMultiplier = 350; // Adjusting for large screens
      imageSpeedMultiplier = 22;
    } else if (viewportWidth >= 1024 && viewportWidth < 1280) { // 'lg' to 'xl'
      titleSpeedMultiplier = 300; // Closer to original for extra-large screens
      imageSpeedMultiplier = 20;
    } else if (viewportWidth >= 1280) { // 'xl' and above
      titleSpeedMultiplier = 250; // Original values for the largest screens
      imageSpeedMultiplier = 20;
    }

    // Calculate speeds using the responsive multipliers
    const titleSpeed = scrollY / titleSpeedMultiplier;
    const imageSpeed = -scrollY / imageSpeedMultiplier;

    return { titleSpeed, imageSpeed };
  };

  const [titleProps, titleApi] = useSpring(() => ({
    to: { transform: 'translateY(0px)' },
    config: { mass: 1, tension: 180, friction: 12 },
  }));

  const [contentProps, contentApi] = useSpring(() => ({
    to: { transform: 'translateY(0px)' },
    config: { mass: 1, tension: 180, friction: 12 },
  }));

  const [imageProps, imageApi] = useSpring(() => ({
    to: { transform: 'translateY(0px)' },
    config: { mass: 5, tension: 150, friction: 50 },
  }));

  const [numberProps, numberApi] = useSpring(() => ({
    to: { transform: 'translateY(0px)' },
    config: { mass: 5, tension: 150, friction: 50 },
  }));

  useEffect(() => {
    const scrollY = window.scrollY;
    const { titleSpeed, imageSpeed } = calculateParallaxSpeeds(scrollY);

    titleApi.start({ transform: `translateY(${titleSpeed}px)` });
    contentApi.start({ transform: `translateY(${titleSpeed}px)` });
    imageApi.start({ transform: `translateY(${imageSpeed}px)` });
    numberApi.start({ transform: `translateY(${imageSpeed}px)` });
  }, [scrollY, titleApi, contentApi, imageApi, numberApi]);

  const renderedTitle = title.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < title.length - 1 && <br />}
    </React.Fragment>
  ));

  const renderedContent = content.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < content.length - 1 && <br />}
    </React.Fragment>
  ));

  const handleMouseEnter = () => {
    if (!disableHover && titleLineRef.current) {
      titleLineRef.current.style.transform = 'scaleX(1)';
    }
  };

  const handleMouseLeave = () => {
    if (!disableHover && titleLineRef.current) {
      titleLineRef.current.style.transform = 'scaleX(0)';
    }
  };

  return (
    <div
      className={`flex ${isEven ? 'flex-row' : 'flex-row-reverse mr-36'} items-start justify-center justify-between p-5 w-full h-auto box-border`}
      style={{ marginTop: `${titleHeight}px`, color: color }}
    >
      <animated.div style={numberProps} className={`flex-grow-0 mt-24 font-bold text-2xl ${isEven ? 'sm:ml-10 md:ml-10 lg:mr-16 xl 2xl' : 'sm:mr-10 md:mr-10 lg:mr-0 xl 2xl'}`} >
        {number.toString().padStart(2, '0')}
      </animated.div>

      <animated.div className={`flex flex-col justify-between ${isEven ? 'items-end' : 'items-start'}  flex-1`} style={titleProps}>
        <Link to={link} className={disableHover ? 'cursor-default' : 'cursor-pointer '}>
          <h4
            ref={titleRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={`title
      text-3xl
      sm:text-4xl
      md:text-6xl
      lg:text-7xl
      xl:text-8xl
      2xl:text-9xl
      py-2 text-${isEven ? 'right' : 'left'}`}
            style={{ fontFamily: 'UI Sans, sans-serif', whiteSpace: 'pre-line' }}
          >
            {renderedTitle}
            <div
              ref={titleLineRef}
              className={`${isEven ? 'title-line-reversed' : 'title-line'} relative bottom-0 bg-${color} left-0 w-full`}
            ></div>
          </h4>
        </Link>
        <animated.div className={`
    sm:text-sm
    md:text-base
    lg:text-lg
    xl:text-xl
    2xl:text-2xl
    text-${isEven ? 'right' : 'left'}`} style={contentProps}>
          {renderedContent}
        </animated.div>
      </animated.div>

      <Link to={link} className={disableHover ? 'cursor-default' : 'cursor-pointer'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <animated.img
          src={img}
          alt="image"
          className={`image 
          sm:w-56
          md:w-80
          lg:w-96
          xl:w-[32rem]
          2xl:w-[42rem]
          max-h-[70vh] self-center ${isEven ? 'sm:mr-6 md:mr-22 lg:mr-26 xl 2xl' : 'sm:ml-6 md:ml-20 lg:ml-20 xl 2xl'}`}
          style={imageProps}
        />
      </Link>
    </div>

  );
};

export default ContentBox;

