import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'wouter';
import TypingAnimation from '../components/common/TypingAnimation';
import ModelViewer from '../components/common/ModelViewer';
import '../static/LandingPage.css';
import ContentBox from '../components/common/ContentBox';
import Footer from '../components/common/Footer';
import useScrollPosition from '../components/common/useScrollPosition';

const LandingPage: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const aboutSectionRef = useRef<HTMLDivElement>(null);
  const [animationPlayed, setAnimationPlayed] = useState(false);
  const projectsSectionRef = useRef<HTMLDivElement>(null);
  const scrollY = useScrollPosition();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !animationPlayed) {
          setAnimationPlayed(true);
        }
      });
    });

    const currentAboutSection = aboutSectionRef.current;

    if (currentAboutSection) {
      observer.observe(currentAboutSection);
    }

    return () => {
      if (currentAboutSection) {
        observer.unobserve(currentAboutSection);
      }
    };
  }, [animationPlayed]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const horizontalMovementFactor = 0.1;
  const arrowDownwardMovementFactor = 0.1;
  const scale = Math.min(1, scrollPosition / 800);

  const arrowDownwardMovement = scrollPosition * arrowDownwardMovementFactor;

  const aboutSectionClass = animationPlayed ? 'reveal' : '';

  const handleProjectsClick = () => {
    projectsSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="w-full min-h-dvh flex flex-col justify-center mt-36 items-center overflow-hidden">
      <div className="fixed top-0 left-0 w-full h-full -z-10">
        <ModelViewer />
      </div>
      <div
        className="transition-transform text-white duration-200 linear transform-gpu absolute"
        style={{
          bottom: `${-5 + arrowDownwardMovement}px`,
          right: '10%',
          opacity: 1,
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 sm:h-16 w-12 sm:w-16 animate-bounce" fill="none" viewBox="0 0 24 24" stroke="black">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </div>
      <div
        className="reveal transition-transform text-[1a1a1a] ease-linear text-[calc(5rem+2vw)] sm:text-[calc(4rem+2vw)] md:text-[calc(5rem+2vw)] lg:text-[calc(8rem+2vw)] xl:text-[calc(8rem+5vw)] md:ml-[10%] lg:ml-[10rem] select-none leading-none mt-14 transform-gpu"
        style={{ transform: `translateX(${scrollPosition * horizontalMovementFactor}px)` }}
      >
        <span>WELCOME</span>
      </div>
      <div
        className="reveal transition-transform text-[1a1a1a] ease-linear text-[calc(5rem+2vw)] sm:text-[calc(4rem+2vw)] md:text-[calc(5rem+2vw)] lg:text-[calc(8rem+2vw)] xl:text-[calc(8rem+5vw)] md:mr-[15%] lg:mr-[20rem] select-none leading-none md:-mt-6 lg:-mt-12 transform-gpu"
        style={{ transform: `translateX(${scrollPosition * -horizontalMovementFactor}px)` }}
      >
        <span>TO MY</span>
      </div>
      <div
        className="reveal transition-transform text-[1a1a1a] ease-linear text-[calc(5rem+2vw)] sm:text-[calc(4rem+2vw)] md:text-[calc(5rem+2vw)] lg:text-[calc(8rem+2vw)] xl:text-[calc(8rem+5vw)] md:ml-[5%] lg:ml-[10rem] select-none leading-none md:-mt-6 lg:-mt-12 transform-gpu"
        style={{ transform: `translateX(${scrollPosition * horizontalMovementFactor}px)` }}
      >
        <span>WORLD</span>
      </div>

      <div className='text-[1a1a1a] select-none font-sans mr-[85%] -mt-[3%] sm:text-xs md:text-xs lg:text-sm xl:text-base'>
        <div className="reveal -mt-3"><span className='desc'>A</span></div>
        <div className="reveal -mt-3"><span className='desc'>world of</span></div>
        <div className="reveal -mt-3"><span className='desc'>tomorrow</span></div>
      </div>
      <div
        className="w-full transition-transform duration-0 ease-linear transform-gpu mt-36"
        style={{
          height: `${50 + scale * 50}vh`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
          <TypingAnimation />
        </div>

        <video
          src="/static/ocean.mp4"
          loop
          autoPlay
          muted
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
      <div className={`about text-left m-5 sm:text-2xl md:text-4xl lg:text-4xl xl:text-5xl 2xl mx-auto sm:ml-10 md:ml-20 lg:ml-64 flex justify-center items-center -mt-44 xl:-mt-26 -mb-56 h-screen`} style={{ fontFamily: 'Times New Roman' }}>
        <div className="flex items-center justify-between w-full">
          <div>
            <div ref={aboutSectionRef} className={`${aboutSectionClass} py-1`}><span>Through perseverance and curiosity, <br /></span></div>
            <div className={`${aboutSectionClass} py-1`}><span> my university journey shapes my future.<br /></span></div>
            <div className={`${aboutSectionClass} py-1`}><span> Embracing challenges, it propels me <br /></span></div>
            <div className={`${aboutSectionClass} py-1`}><span> towards excellence and personal growth.</span></div>
            <Link href="/about" className="py-2 px-6 sm:px-8 md:px-10 rounded-full cursor-pointer mt-2 link-hovers text-sm sm:text-md md:text-lg" style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', minWidth: '200px' }}>
              About me  
            <div style={{ marginLeft: '8px' }}>→</div> </Link> </div> <div onClick={handleProjectsClick} className='reveal font-sans cursor-pointer text-[1a1a1a] text-right text-xl mt-[20%] -mr-[75%] sm:text-xs md:text-xs lg:text-sm xl:text-base'> <p> my <br /></p> <p className='-mt-2'> latest </p> <p className='-mt-2'> projects </p> </div> </div> </div>

            <div ref={projectsSectionRef}>
    <div className="border border-t border-gray-300 w-[90%] mx-auto mt-4 fade-border"></div>
    <ContentBox
      img="./static/projectsImage/fht.png"
      title={["Face &", "Hand Tracker"]}
      content={["Python, OpenCV, cvzone, MediaPipe"]}
      number={1}
      link={"/face-hand-tracker"}
      scrollY={scrollY}
    />

    <div className="border border-t border-gray-300 w-[90%] mx-auto mt-4 fade-border"></div>

    <ContentBox
      img="./static/projectsImage/dls.png"
      title={["Deep", "Link Sorter"]}
      content={["Python, PyTorch, SentenceTransformer, BeautifulSoup"]}
      number={2}
      link={"/deep-link-sorter"}
      scrollY={scrollY}
    />

    <div className="border border-t border-gray-300 w-[90%] mx-auto mt-4 fade-border"></div>

    <ContentBox
      img="./static/projectsImage/wbst.png"
      title={["This", "Website"]}
      content={["React, Python, nginx, SQLite"]}
      number={3}
      link={"/website"}
      scrollY={scrollY}
    />

    <div className="border border-t border-gray-300 w-[90%] mx-auto mt-4 fade-border"></div>

    <ContentBox
      img="./static/projectsImage/ws.png"
      title={["Weather", "Station"]}
      content={["React, Python, nginx, SQLite, API"]}
      number={4}
      link={"/weather-station"}
      scrollY={scrollY}
    />

    <div className="border border-t border-gray-300 w-[90%] mx-auto mt-4 fade-border"></div>

    <ContentBox
      img="./static/projectsImage/ntkl.png"
      title={["Nattklar"]}
      content={["Jetpack Compose, Kotlin, API"]}
      number={5}
      link={"/nattklar"}
      scrollY={scrollY}
    />

  </div>
  <Footer />
</div>
);
};

export default LandingPage;