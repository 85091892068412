// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  position: relative;
  overflow: hidden;
  border: none;
  /* Adjust padding as needed */
  cursor: pointer;
  /* To indicate it's clickable */
  transition: background-color 0.3s ease;
  /* Smooth transition for background color */
}

.title:hover {
  background-color: transparent;
  /* Removes grey box on hover */
}

.title-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  /* Line color */
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}

.title:hover .title-line {
  transform: scaleX(1);
}

.title-line-reversed {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  /* Line color */
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease-in-out;
}

.title:hover .title-line-reversed {
  transform: scaleX(1);
}`, "",{"version":3,"sources":["webpack://./src/static/ContentBox.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,+BAA+B;EAC/B,sCAAsC;EACtC,2CAA2C;AAC7C;;AAEA;EACE,6BAA6B;EAC7B,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,WAAW;EACX,eAAe;EACf,oBAAoB;EACpB,sBAAsB;EACtB,sCAAsC;AACxC;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,WAAW;EACX,eAAe;EACf,oBAAoB;EACpB,uBAAuB;EACvB,sCAAsC;AACxC;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".title {\r\n  position: relative;\r\n  overflow: hidden;\r\n  border: none;\r\n  /* Adjust padding as needed */\r\n  cursor: pointer;\r\n  /* To indicate it's clickable */\r\n  transition: background-color 0.3s ease;\r\n  /* Smooth transition for background color */\r\n}\r\n\r\n.title:hover {\r\n  background-color: transparent;\r\n  /* Removes grey box on hover */\r\n}\r\n\r\n.title-line {\r\n  position: absolute;\r\n  bottom: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 2px;\r\n  /* Line color */\r\n  transform: scaleX(0);\r\n  transform-origin: left;\r\n  transition: transform 0.3s ease-in-out;\r\n}\r\n\r\n.title:hover .title-line {\r\n  transform: scaleX(1);\r\n}\r\n\r\n.title-line-reversed {\r\n  position: absolute;\r\n  bottom: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 2px;\r\n  /* Line color */\r\n  transform: scaleX(0);\r\n  transform-origin: right;\r\n  transition: transform 0.3s ease-in-out;\r\n}\r\n\r\n.title:hover .title-line-reversed {\r\n  transform: scaleX(1);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
