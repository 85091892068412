import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';

const FaceHandTracker: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null); // Keep for capturing frames
  const photoRef = useRef<HTMLImageElement>(null);
  const messageRef = useRef<HTMLDivElement>(null);
  const [canConnect, setCanConnect] = useState(false);

  useEffect(() => {
    const checkConnectionLimit = async () => {
      try {
        const response = await axios.get('/face-hand-tracker/check_connection_limit');
        setCanConnect(response.data.can_connect);
      } catch (error) {
        console.error('Error checking connection limit:', error);
      }
    };

    checkConnectionLimit();
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL || '';
    const namespace = '/face-hand-tracker';
    const socket: Socket = io(`${SOCKET_SERVER_URL}${namespace}`, {
      transports: ['websocket']
    });

    socket.on('connect', () => {
      console.log(`Connected to namespace ${namespace}...!`);
    });

    if (navigator.mediaDevices.getUserMedia && canConnect) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.play();
          }
        })
        .catch(error => console.log("An error occurred: ", error));
    }

    const FPS = 6;
    const intervalId = setInterval(() => {
      if (videoRef.current && canvasRef.current && canConnect) {
        const context = canvasRef.current.getContext('2d');
        const width = videoRef.current.videoWidth;
        const height = videoRef.current.videoHeight;
        canvasRef.current.width = width;
        canvasRef.current.height = height;
        // Mirror the video
        context?.translate(width, 0);
        context?.scale(-1, 1);
        context?.drawImage(videoRef.current, 0, 0, width, height);
        const data = canvasRef.current.toDataURL('image/jpeg', 0.5);
        socket.emit('image', data);
      }
    }, 1000 / FPS);

    socket.on('response_back', (image: string) => photoRef.current && (photoRef.current.src = image));
    socket.on('gesture_message', (message: string) => messageRef.current && (messageRef.current.innerText = message));

    return () => {
      clearInterval(intervalId);
      socket.disconnect();
      if (videoElement?.srcObject) { // Use the copied variable here
        const tracks = (videoElement.srcObject as MediaStream).getTracks();
        tracks.forEach(track => track.stop());
      }
    };
  }, [canConnect]);
  
  return (
    <div className="flex flex-col justify-center items-center h-screen p-4">
      {canConnect ? (
        <>
          <div className="flex flex-wrap justify-center items-center gap-4">
            <video ref={videoRef} className="w-96 inline-block" autoPlay playsInline></video>
            <canvas ref={canvasRef} className="hidden"></canvas>
            <img ref={photoRef} alt="Processed Frame" className="w-96 inline-block" />
          </div>
          <div id="gesture-message" ref={messageRef} className="mt-5 text-2xl text-green-500"></div>
        </>
      ) : (
        <div className="text-2xl text-red-500">Connection limit reached. Please try again later.</div>
      )}
      <div className="bg-orange-500 bg-opacity-60 rounded-lg text-left shadow-md p-4 max-w-4xl text-white">
        <p className="mb-4">
          The project showcased the integration of machine learning into practical, real-life applications, particularly benefiting individuals with disabilities.
        </p>
        <p className="mb-4">
          By leveraging advanced technologies and programming libraries—like OpenCV for image processing, MediaPipe for facial and hand recognition, and Flask-SocketIO for real-time communication—this enhanced system provides an interactive, responsive experience.
        </p>
        <p className="mb-4">
          Through the processing of base64 encoded images sent by clients, it identifies facial features and hand gestures. The results are then communicated back to the clients instantly.
        </p>
        <p>
          This seamless interaction opens up new avenues for accessibility, enabling people with disabilities to interact with technology in ways that are tailored to their needs. For example, someone with limited mobility could use hand gestures to control a computer or navigate through a digital interface, reducing the need for traditional input devices that might be challenging to use. This not only showcases the power of machine learning but also highlights its potential to make technology more inclusive, creating opportunities for those who might otherwise be excluded from certain aspects of digital life.
        </p>
      </div>
    </div>
  );
};

export default FaceHandTracker;