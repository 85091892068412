import { useState, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';
import UseCsrfToken from '../../hooks/UseCsrfToken';
import handleAxiosError from '../../components/error/AxiosError';
import { Link, useLocation } from 'wouter'; // Import useLocation here
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useRedirectIfAuthenticated from '../../hooks/UseRedirectIfAuthenticated';

interface FormData {
    username: string;
    password: string;
    email: string;
    first_name: string;
    last_name: string;
}

function SignupForm() {
    useRedirectIfAuthenticated('/'); 
    
    const [formData, setFormData] = useState<FormData>({
        username: '',
        password: '',
        email: '',
        first_name: '',
        last_name: '',
    });
    const [errors, setErrors] = useState<Record<string, string[]>>({});
    const csrfToken = UseCsrfToken();
    const [showPassword, setShowPassword] = useState(false);
    const [, navigate] = useLocation(); // Correctly destructure to get the navigate function

    const errorPlaceholder = "errorPlaceHolder min-h-[16px]"; // Adjust the height based on your error message size

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({});
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await axios.post('/api/signup', formData, {
                headers: {
                    'X-CSRFToken': csrfToken,
                },
            });
            navigate(`/emailConfirm/${encodeURIComponent(formData.email)}`);

        } catch (error) {
            const processedErrors = handleAxiosError(error);
            setErrors(processedErrors);
            console.error('There was an error:', processedErrors);
        }
    };

return (
    <main className="flex text-left flex-col justify-center items-center h-screen space-y-4" role="main">
        <div className="w-full max-w-md mx-auto">
            <form onSubmit={handleSubmit} className="space-y-6 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <header className="mb-8 text-left">
                    {/* More descriptive comment for the logo replacement */}
                    <img src="/static/adamkq_logo.png" alt="adamkq" className="mx-auto h-12 w-auto ml-0" />
                    <h2 className="text-2xl font-bold text-gray-900 mb-2">Create Your Account</h2>
                    <p className="text-gray-600">Join us and start your journey!</p>
                </header>

                <fieldset className="flex flex-col">
                    <label htmlFor="username" className="mb-2 text-sm font-bold text-gray-700">Username</label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="username"
                        name="username"
                        placeholder="Choose a username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                    {/* Error messages for username */}
                    <div className={errorPlaceholder}>
                        {errors.username && errors.username.map((error, index) => <p key={index} className="text-red-500 text-xs italic">{error}</p>)}
                    </div>
                </fieldset>

                {/* Fields for First Name and Last Name with responsive layout */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <fieldset className="flex flex-col">
                        <label htmlFor="first_name" className="mb-2 text-sm font-bold text-gray-700">First Name</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="first_name"
                            name="first_name"
                            placeholder="Enter your first name"
                            value={formData.first_name}
                            onChange={handleChange}
                            required
                        />
                        {/* Error messages for first name */}
                        <div className={errorPlaceholder}>
                            {errors.first_name && errors.first_name.map((error, index) => <p key={index} className="text-red-500 text-xs italic">{error}</p>)}
                        </div>
                    </fieldset>

                    <fieldset className="flex flex-col">
                        <label htmlFor="last_name" className="mb-2 text-sm font-bold text-gray-700">Last Name</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="last_name"
                            name="last_name"
                            placeholder="Enter your last name"
                            value={formData.last_name}
                            onChange={handleChange}
                            required
                        />
                        {/* Error messages for last name */}
                        <div className={errorPlaceholder}>
                            {errors.last_name && errors.last_name.map((error, index) => <p key={index} className="text-red-500 text-xs italic">{error}</p>)}
                        </div>
                    </fieldset>
                </div>

                <fieldset className="flex flex-col">
                    <label htmlFor="email" className="mb-2 text-sm font-bold text-gray-700">Email Address</label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    {/* Error messages for email */}
                    <div className={errorPlaceholder}>
                        {errors.email && errors.email.map((error, index) => <p key={index} className="text-red-500 text-xs italic">{error}</p>)}
                    </div>
                </fieldset>

                <fieldset className="flex flex-col relative">
                    <label htmlFor="password" className="mb-2 text-sm font-bold text-gray-700">Password</label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline pr-10"
                        id="password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Choose a strong password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                    <button
                        type="button"
                        className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-500"
                        onClick={() => setShowPassword(!showPassword)}
                        aria-label={showPassword ? "Hide password" : "Show password"}
                    >
                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </button>
                    {/* Error messages for password */}
                    <div className={errorPlaceholder}>
                        {errors.password && errors.password.map((error, index) => <p key={index} className="text-red-500 text-xs italic">{error}</p>)}
                    </div>
                </fieldset>
                
                <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">Create Account</button>

                <footer className="mt-4 text-center">
                    Already have an account? <Link to="/login" className="text-blue-500 hover:text-blue-700">Log in</Link>
                </footer>
            </form>
        </div>
    </main>
);
}

export default SignupForm;
