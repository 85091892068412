import { useState, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';
import UseCsrfToken from '../../hooks/UseCsrfToken';
import handleAxiosError from '../../components/error/AxiosError';
import { Link } from 'wouter';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../../context/AuthContext';
import useRedirectIfAuthenticated from '../../hooks/UseRedirectIfAuthenticated';

interface FormData {
    username_email: string;
    password: string;
    remember_me: boolean;
}

function LoginForm() {
    useRedirectIfAuthenticated('/'); 
    const [formData, setFormData] = useState<FormData>({
        username_email: '',
        password: '',
        remember_me: false,
    });
    const [errors, setErrors] = useState<Record<string, string[]>>({});
    const csrfToken = UseCsrfToken();
    const { login } = useAuth();
    const [showPassword, setShowPassword] = useState(false);

    const errorPlaceholder = "errorPlaceHolder min-h-[24px]";

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));
        setErrors({});
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await axios.post('/api/login', formData, {
                headers: {  
                    'X-CSRFToken': csrfToken
                }
            });
            login()
            setFormData({
                username_email: '',
                password: '',
                remember_me: false,
            });
        } catch (error) {
            const processedErrors = handleAxiosError(error);
            setErrors(processedErrors);
            console.error('There was an error:', processedErrors);
        }
    };
    return (
        <main className="flex text-left flex-col justify-center items-center h-screen space-y-4" role="main">
            <div className="w-full max-w-md mx-auto">
                <form onSubmit={handleSubmit} className="space-y-6 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <header className="mb-8 text-left">
                        <img src="/static/adamkq_logo.png" alt="adamkq" className="mx-auto h-12 w-auto ml-0" />
                        <h2 className="text-2xl font-bold text-gray-900 mb-2">Login to Your Account</h2>
                        <p className="text-gray-600">Welcome back! Please enter your details to login.</p>
                    </header>
    
                    <fieldset className="flex flex-col mb-4">
                        <label htmlFor="username_email" className="mb-2 text-sm font-bold text-gray-700">Username or Email</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="username_email"
                            name="username_email"
                            placeholder="Your username or email"
                            value={formData.username_email}
                            onChange={handleChange}
                            required
                        />
                        <div className={errorPlaceholder}>
                            {errors.username_email && errors.username_email.map((error, index) => <p key={index} className="text-red-500 text-xs italic">{error}</p>)}
                        </div>
                    </fieldset>
    
                    <fieldset className="flex flex-col mb-4 relative">
                        <label htmlFor="password" className="mb-2 text-sm font-bold text-gray-700">Password</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline pr-10"
                            id="password"
                            name="password"
                            placeholder="Your password"
                            type={showPassword ? "text" : "password"}
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                        <button
                            type="button"
                            className="absolute bottom-1 inset-y-0 right-0 px-3 flex items-center text-gray-500"
                            onClick={() => setShowPassword(!showPassword)}
                            aria-label={showPassword ? "Hide password" : "Show password"}
                        >
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </button>
                        <div className={errorPlaceholder}>
                            {errors.password && errors.password.map((error, index) => <p key={index} className="text-red-500 text-xs italic">{error}</p>)}
                        </div>
                    </fieldset>
    
                    <div className="flex items-center justify-between mb-6">
                        <label htmlFor="remember_me" className="flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                id="remember_me"
                                name="remember_me"
                                className="form-checkbox h-5 w-5 text-blue-600"
                                checked={formData.remember_me}
                                onChange={handleChange}
                            />
                            <span className="ml-2 text-sm text-gray-700">Remember Me</span>
                        </label>
                        <Link to="/forgot-password" className="text-sm text-blue-500 hover:text-blue-700">Forgot Password?</Link>
                    </div>
                    
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">Log In</button>
    
                    <footer className="mt-4 text-center">
                        Don't have an account? <Link to="/signup" className="text-blue-500 hover:text-blue-700">Sign up!</Link>
                    </footer>
                </form>
            </div>
        </main>
    );    
}

export default LoginForm;