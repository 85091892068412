import axios, { AxiosError } from 'axios';

interface ApiResponseError {
  message?: string;
  errors?: Record<string, string | string[]>;
}

// Converts all errors into a standardized format
function handleAxiosError(error: unknown): Record<string, string[]> {
  const normalizedErrors: Record<string, string[]> = {};

  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError<ApiResponseError>;
    if (axiosError.response && axiosError.response.data) {
      if (axiosError.response.data.errors) {
        // Convert each field error into an array of strings
        Object.entries(axiosError.response.data.errors).forEach(([key, value]) => {
          normalizedErrors[key] = Array.isArray(value) ? value : [value];
        });
      } else if (axiosError.response.data.message) {
        // Handle general error messages
        normalizedErrors.general = [axiosError.response.data.message];
      }
    }
  } else {
    // Fallback for non-Axios errors
    normalizedErrors.general = ['An error occurred. Please try again.'];
  }

  return normalizedErrors;
}


export default handleAxiosError;
