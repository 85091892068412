import { useState, useEffect } from 'react';
import axios from 'axios';

function UseCsrfToken(): string {
  const [csrfToken, setCsrfToken] = useState<string>('');

useEffect(() => {
  async function fetchCsrfToken() {
    try {
      const response = await axios.get('/api/csrf');
      setCsrfToken(response.data.csrf_token);
    } catch (error) {
      console.error('Failed to fetch CSRF token:', error);
    }
  }
  fetchCsrfToken();
}, []);


  return csrfToken;
}

export default UseCsrfToken;