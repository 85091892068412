import '../static/Contact.css';

const Contact = () => {
  return (
    <div className="flex flex-col sm:flex-row w-full text-left min-h-screen bg-black text-white overflow-hidden">
      <div className="w-full sm:w-3/5 flex flex-col justify-center items-start py-16 px-8 sm:py-32 sm:pl-32 select-none">
        <h1 className="text-6xl font-bold tracking-wide uppercase sm:text-7xl md:text-8xl lg:text-9xl">
          <span>You're</span><br />
          <span>never far!</span>
        </h1>
      </div>

      {/* Right side with contact info */}
      <div className="w-full sm:w-1/3 flex flex-col justify-between py-8 px-8 sm:py-auto">
        {/* Contact Info */}
        <div className='mt-auto'>
          <div className="mb-8 sm:mb-12">
            <h2 className="font-semibold mb-2 text-2xl sm:text-3xl md:text-4xl lg:text-5xl select-none">Get in touch</h2>
            <p>
              <a href="mailto:adamkarlqueja@protonmail.com" className="custom-underline text-base sm:text-lg md:text-xl lg:text-2xl">adamkarlqueja@protonmail.com</a>
            </p>
            <p className="text-base sm:text-lg md:text-xl lg:text-2xl">+47 477 37 430</p>
          </div>


          <div className="mb-8 sm:mb-12">
            <h2 className="font-semibold mb-2 text-2xl sm:text-3xl md:text-4xl lg:text-5xl select-none">Connect with me</h2>
            <p>
              <a href="https://www.instagram.com/adamkarl8/" target="_blank" rel="noopener noreferrer" className="custom-underline text-base sm:text-lg md:text-xl lg:text-2xl">Instagram</a>
            </p>
            <p>
              <a href="https://www.linkedin.com/in/adam-karl02/" target="_blank" rel="noopener noreferrer" className="custom-underline text-base sm:text-lg md:text-xl lg:text-2xl">LinkedIn</a>
            </p>
            <p>
              <a href="https://discord.com/users/193097321813245952" target="_blank" rel="noopener noreferrer" className="custom-underline text-base sm:text-lg md:text-xl lg:text-2xl">Discord</a>
            </p>
          </div>
        </div>

        {/* Footer */}
        <div className="mt-auto text-xs sm:text-sm md:text-base lg:text-lg select-none">
          <p>© {new Date().getFullYear()} adamkq</p>
          <p>All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;