interface ErrorDisplayProps {
  errors: Record<string, string[]>;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ errors }) => {
  const errorKeys = Object.keys(errors);
  if (errorKeys.length === 0) return null;

  return (
    <div>
      {errorKeys.flatMap(key => 
        errors[key].map((message, index) => (
          <p key={`${key}-${index}`} style={{ color: 'red' }}>
            {message}
          </p>
        ))
      )}
    </div>
  );
};

export default ErrorDisplay;
