import React from 'react';

const Nattklar: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center h-max p-4 mt-24">
      <h1 className="text-2xl font-bold mb-4">Nattklar</h1>
      <div className="bg-orange-500 bg-opacity-60 rounded-lg text-left shadow-md p-4 max-w-4xl text-white">
        <section className="overview">
          <h2 className="text-xl font-bold mb-2">Overview</h2>
          <p className="drop-shadow-[0_0_8px_rgba(0,0,0,0.2)]">
            Nattklar is a project developed by a team of students at UiO for the course IN2000. It is a stargazing app
            that allows users to see the conditions for how visible constellations for the upcoming nights. The app
            gets this information by querying MET APIs with parameters from the users location given they give
            permissions. Otherwise you can manually search for locations in the globescreen. The app also has other
            functionality such as largely personally written information about common astronomical objects and seeing
            upcoming and previous astronomical news. My role in this project consisted of UI/UX Design and Backend.
          </p>
          <br />
        </section>

        <section className="dls-core-components">
          <br />
          <h2 className="text-xl font-bold mb-2">Homescreen</h2>
          <p className="drop-shadow-[0_0_8px_rgba(0,0,0,0.2)]">
            This screen shows a summary of the most relevant data for stargazing. If you have given the app location
            permissions this will show your location, the time for tonights sunset for your location, a list of
            upcoming astronomical events and a list of constellations with a grading of each constellation's
            visibility and a short description.
          </p>
          <div className="flex justify-center">
            <img src="/static/projectsImage/nattklar/homescreen.png" className="w-44" alt="Homescreen" />
          </div>

          <h2 className="text-xl font-bold mb-2">Globescreen</h2>
          <p className="drop-shadow-[0_0_8px_rgba(0,0,0,0.2)]">
            This screen shows a summary of the most relevant data for stargazing. If you have given the app location
            permissions this will show your location, the time for tonights sunset for your location, a list of
            upcoming astronomical events and a list of constellations with a grading of each constellation's
            visibility and a short description.
          </p>
          <div className="flex justify-center">
            <img src="/static/projectsImage/nattklar/globescreen.png" className="w-56" alt="Globescreen" />
            <img src="/static/projectsImage/nattklar/weatherconditions.png" className="w-56" alt="Weather Conditions" />
          </div>

          <h2 className="text-xl font-bold mb-2">Newsscreen</h2>
          <p className="drop-shadow-[0_0_8px_rgba(0,0,0,0.2)]">This screen shows upcoming and previouos astronomical events.</p>
          <div className="flex justify-center">
            <img src="/static/projectsImage/nattklar/newsscreen.png" className="w-56" alt="Newsscreen" />
          </div>

          <h2 className="text-xl font-bold mb-2">Wikiscreen</h2>
          <p className="drop-shadow-[0_0_8px_rgba(0,0,0,0.2)]">
            This screen shows information about astronomical objects such as constellations, individual stars, planets
            &amp; moons and comets. Each constellation has a personally written description and the other objects'
            descriptions are taken from snl.
          </p>
          <div className="flex justify-center">
            <img src="/static/projectsImage/nattklar/wikiscreen.png" className="w-56" alt="Wikiscreen" />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Nattklar;