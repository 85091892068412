import React from 'react';

const WeatherStation: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen p-4 mt-24">
      <h1 className="text-2xl font-bold mb-4">Weather Station</h1>
      <div className="bg-orange-500 bg-opacity-60 rounded-lg text-left shadow-md p-4 max-w-4xl text-white">
        <section className="overview">
          <h2 className="text-xl font-bold mb-2">Overview</h2>
          <p className="drop-shadow-[0_0_8px_rgba(0,0,0,0.2)]">
            Weather Station serves as a internal application for Portal Space, designed specifically to
            assess and confirm the launch-ready status for rocket launches.
            This web-based tool uses meteorological data sourced from MET
            along with GRIB data. The integration and analysis of data from these sources
            helps us determine whether the  weather conditions meet our
            condtions for launch status.
          </p>
        </section>

        <section className="dls-core-components mt-6">
          <h2 className="text-xl font-bold mb-2">Core Features and Technologies</h2>

          <p className="drop-shadow-[0_0_8px_rgba(0,0,0,0.2)]">
            At the heart of the Weather Station web app lies on Flask, an robust
            and flexible web framework built on Python and React for the frontend. The choice was made on data analysis, 
            due to the simplicity of analysing data with Python, it was chosen that the backend would be flask.
            A similar but stricter user system from this website is implemented in Weather Station. This is to
            ensure that only Portal Space members have access. 
          </p>
          <br />
          <p className="drop-shadow-[0_0_8px_rgba(0,0,0,0.2)]">
            The core functionality of Weather Station revolves around the  handling and processing of
            meteorological data through Python's powerful data analysis. This processed data is
            subsequently stored in a CSV file which is then updated when new data is ready. When Weather Station is
            accessed ie. HTTP request, then it will send an API where necessary data is sent to the client which will then be 
            viewed with React.
          </p>
        </section>
      </div>
    </div>
  );
};

export default WeatherStation;