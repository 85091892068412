import { Link } from 'wouter';
import '../../static/Contact.css';

const Footer = () => {
  return (
    <footer className="bg-[#161818] w-full text-white text-sm p-4">
      <div className="w-3/5 mx-auto flex flex-col mt-24 items-left">
        <div className="w-full flex flex-col md:flex-row justify-center items-stretch mb-24 space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex flex-1 flex-col items-start px-4 py-4">
            <h2 className="text-2xl mb-2">
              Ready to collaborate <br />
              or a website built?
            </h2>
            <Link href="/contact" className="py-2 px-6 sm:px-8 md:px-10 rounded-full cursor-pointer mt-2 link-hover text-sm sm:text-md md:text-lg" style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', minWidth: '200px' }}>
              Contact me <div style={{ marginLeft: '8px' }}>→</div>
            </Link>
          </div>

          <div className="flex flex-1 flex-col items-start border-0  px-4 py-4 mt-32">
          </div>

          <div className="flex flex-1 flex-col items-start border-0  px-4 py-4 mt-32">
            <Link href="/" className="text-xl"><span>HOME</span></Link>
            <Link href="/about" className="text-xl -mt-2"><span>ABOUT</span></Link>
            <Link href="/contact" className="text-xl -mt-2"><span>CONTACT</span></Link>
            <Link href="/login" className="text-xl -mt-2"><span>LOGIN</span></Link>
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row justify-center items-stretch mb-4 space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex flex-1 flex-col items-start border-0 border-t border-white px-4 py-4">
            <p className="font-semibold mb-2 text-left w-full">GET IN TOUCH</p>
            <p className="text-left w-full">
              <a href="mailto:adamkarlqueja@protonmail.com" className="custom-underline">
                adamkarlqueja@protonmail.com
              </a>
            </p>
            <p className="text-left w-full">+47 477 37 430</p>
          </div>

          <div className="flex flex-1 flex-col items-start border-0 border-t border-white px-4 py-4">
            <p className="font-semibold mb-2 text-left w-full">CONNECT WITH ME</p>
            <a href="https://www.instagram.com/adamkarl8/" className="custom-underline">Instagram</a>
            <a href="https://www.linkedin.com/in/adam-karl02/" className="custom-underline">LinkedIn</a>
            <a href="https://discord.com/users/193097321813245952" className="custom-underline">Discord</a>
          </div>

          <div className="flex flex-1 flex-col items-start border-0 border-t border-white px-4 py-4">
            <p className="font-semibold mb-2 text-left w-full">CONTRIBUTIONS</p>
            <a href="https://storyset.com/technology" className="custom-underline">Technology illustrations by Storyset</a>
          </div>
        </div>

        <div className="text-left">
          <p>© {new Date().getFullYear()} adamkq. <br /> All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;