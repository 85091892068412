import React, { useState } from 'react';
import axios from 'axios';
import UseCsrfToken from '../../hooks/UseCsrfToken';
import ErrorDisplay from '../../components/error/ErrorDisplay';
import handleAxiosError from '../../components/error/AxiosError';

interface ForgotPasswordFormProps {}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = () => {
    const [email, setEmail] = useState<string>('');
    const [errors, setErrors] = useState<Record<string, string[]>>({});
    const [successMessage, setSuccessMessage] = useState<string>('');
    const csrfToken: string = UseCsrfToken();

    const errorPlaceholder = "errorPlaceHolder min-h-[24px]"; // Adjust the height based on your error message size

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setErrors({});
        setSuccessMessage('');
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/forgot_password', { email }, {
                headers: {
                    'X-CSRFToken': csrfToken,
                }
            });
            setSuccessMessage(response.data.message);
            setEmail(''); // Reset email after successful operation
        } catch (error) {
            const processedErrors = handleAxiosError(error);
            setErrors(processedErrors);
            console.error('There was an error:', processedErrors);
        }
    };

    return (
        <main className="flex text-left flex-col justify-center items-center h-screen space-y-4" role="main">
            <div className="w-full max-w-md mx-auto">
                <form onSubmit={handleSubmit} className="space-y-6 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <header className="mb-8 text-left">
                    <img src="/static/adamkq_logo.png" alt="adamkq" className="mx-auto h-12 w-auto ml-0" />
                        <h2 className="text-2xl font-bold text-gray-900 mb-2">Reset Your Password</h2>
                        <p className="text-gray-600">Enter your email address to receive a password reset link.</p>
                    </header>
    
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email Address</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email"
                            type="email"
                            name="email"
                            placeholder="you@example.com"
                            value={email}
                            onChange={handleChange}
                            aria-describedby="emailHelp"
                            required
                        />
                        <p id="emailHelp" className="text-xs italic text-gray-500">We'll never share your email with anyone else.</p>
                        <div className={errorPlaceholder}>
                            <ErrorDisplay errors={errors}/>
                        </div>
                    </div>
    
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" aria-label="Submit reset password request">
                        Send Reset Link
                    </button>
    
                    <div className={errorPlaceholder}>
                        {successMessage && <div className="text-sm text-left text-green-500">{successMessage}</div>}
                    </div>
                </form>
            </div>
        </main>
    );
    
}

export default ForgotPasswordForm;
