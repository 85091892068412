import React, { useEffect, useState } from 'react';
import { useParams } from 'wouter';
import axios from 'axios';
import ErrorDisplay from '../components/error/ErrorDisplay';
import handleAxiosError from '../components/error/AxiosError';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'; // Make sure to install @heroicons/react if you haven't already

interface ConfirmationPageProps {}

const ConfirmationPage: React.FC<ConfirmationPageProps> = () => {
  const params = useParams<{token: string}>();
  const { token } = params;
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const [successMessage, setSuccessMessage] = useState<string>('');

  useEffect(() => {
    const confirmAccount = async () => {
      if (!token) return;

      try {
        const response = await axios.get(`/api/confirm/${token}`);
        setSuccessMessage(response.data.message);
      } catch (error) {
        const processedErrors = handleAxiosError(error);
        setErrors(processedErrors);
        console.error('There was an error:', processedErrors);
      }
    };

    confirmAccount();
  }, [token]);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="w-full max-w-lg p-8 space-y-6 bg-white shadow-lg rounded-xl text-center">
        {/* Success message with checkmark */}
        {successMessage && (
          <div className="space-y-4">
            <CheckCircleIcon className="mx-auto h-12 w-12 text-green-500" />
            <h1 className="text-2xl font-semibold text-gray-900">Congrats! 🎉</h1>
            <p className="text-gray-600">{successMessage}</p>
          </div>
        )}
        {/* Error message with red X icon */}
        {Object.keys(errors).length > 0 && (
          <div className="space-y-4">
            <XCircleIcon className="mx-auto h-12 w-12 text-red-500" />
            <h1 className="text-2xl font-semibold text-gray-900">Oops!</h1>
            <ErrorDisplay errors={errors} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmationPage;
