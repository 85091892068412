// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-hovers {
    position: relative;
    display: inline-block;
    overflow: hidden;
    background-image: linear-gradient(to right, black 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    background-color: transparent;
    border: 1px solid black;
    color: black;
    transition: all 0.3s ease;
}

.link-hovers:hover {
    background-position: left bottom;
    color: white;
}

@keyframes textReveal {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

.reveal {
    overflow: hidden;
}

.reveal span {
    display: block;
    animation: textReveal 1.5s cubic-bezier(0.77, 0, 0.175, 1) forwards;
    -webkit-animation: textReveal 1.5s cubic-bezier(0.77, 0, 0.175, 1) forwards;
}

.reveal .desc {
    display: block;
    animation: textReveal 2.3s cubic-bezier(0.77, 0, 0.175, 1) forwards;
    -webkit-animation: textReveal 2.3s cubic-bezier(0.77, 0, 0.175, 1) forwards;
}`, "",{"version":3,"sources":["webpack://./src/static/LandingPage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,gBAAgB;IAChB,uEAAuE;IACvE,0BAA0B;IAC1B,iCAAiC;IACjC,6BAA6B;IAC7B,uBAAuB;IACvB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,gCAAgC;IAChC,YAAY;AAChB;;AAEA;IACI;QACI,2BAA2B;IAC/B;;IAEA;QACI,wBAAwB;IAC5B;AACJ;;AAYA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,mEAAmE;IACnE,2EAA2E;AAC/E;;AAEA;IACI,cAAc;IACd,mEAAmE;IACnE,2EAA2E;AAC/E","sourcesContent":[".link-hovers {\r\n    position: relative;\r\n    display: inline-block;\r\n    overflow: hidden;\r\n    background-image: linear-gradient(to right, black 50%, transparent 50%);\r\n    background-size: 200% 100%;\r\n    background-position: right bottom;\r\n    background-color: transparent;\r\n    border: 1px solid black;\r\n    color: black;\r\n    transition: all 0.3s ease;\r\n}\r\n\r\n.link-hovers:hover {\r\n    background-position: left bottom;\r\n    color: white;\r\n}\r\n\r\n@keyframes textReveal {\r\n    from {\r\n        transform: translateY(100%);\r\n    }\r\n\r\n    to {\r\n        transform: translateY(0);\r\n    }\r\n}\r\n\r\n@-webkit-keyframes textReveal {\r\n    from {\r\n        transform: translateY(100%);\r\n    }\r\n\r\n    to {\r\n        transform: translateY(0);\r\n    }\r\n}\r\n\r\n.reveal {\r\n    overflow: hidden;\r\n}\r\n\r\n.reveal span {\r\n    display: block;\r\n    animation: textReveal 1.5s cubic-bezier(0.77, 0, 0.175, 1) forwards;\r\n    -webkit-animation: textReveal 1.5s cubic-bezier(0.77, 0, 0.175, 1) forwards;\r\n}\r\n\r\n.reveal .desc {\r\n    display: block;\r\n    animation: textReveal 2.3s cubic-bezier(0.77, 0, 0.175, 1) forwards;\r\n    -webkit-animation: textReveal 2.3s cubic-bezier(0.77, 0, 0.175, 1) forwards;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
