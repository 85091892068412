// AuthContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { useNotification } from '../components/common/Notification';

interface AuthContextType {
  isAuthenticated: boolean;
  username: string;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [username, setUsername] = useState<string>('');
    const { notify } = useNotification();

  useEffect(() => {
    checkSession();
  }, []);

  const checkSession = async (): Promise<void> => {
    try {
      const response = await axios.get('/api/check_session', { withCredentials: true });
      setIsAuthenticated(response.status === 200 && response.data.logged_in);
      setUsername(response.status === 200 && response.data.logged_in ? response.data.username : '');
    } catch (error) {
      console.error("Failed to check session:", error);
      setIsAuthenticated(false);
      setUsername('');
    }
  };

const login = async (): Promise<void> => {
  try {
    setIsAuthenticated(true);
    notify('You have logged in!', 'bg-green-500', `Make yourself at home!`);
    await checkSession(); 
  } catch (error) {
    console.error("Failed to login:", error);
    notify('Failed to log in', 'bg-red-500', 'Something wrong happened!');
  }
}

const logout = async (): Promise<void> => {
  try {
    const csrfResponse = await axios.get('/api/csrf', { withCredentials: true });
    const newCsrfToken = csrfResponse.data.csrf_token; 

    await axios.post('/api/logout', {}, {
      withCredentials: true,
      headers: { 'X-CSRFToken': newCsrfToken },
    });

    setIsAuthenticated(false);
    notify('You have logged out!', 'bg-yellow-500', 'Hope you come back!');
    window.location.reload();
  } catch (error) {
    console.error("Logout failed:", error);
    notify('Logout failed', 'bg-red-500', 'Something wrong happened!');
  }
};

  return (
    <AuthContext.Provider value={{ isAuthenticated, username, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
