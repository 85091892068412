// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";

.custom-underline {
  position: relative;
  display: inline-block;
}

.footer {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.custom-underline::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.custom-underline:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.link-hover {
  position: relative;
  display: inline-block;
  overflow: hidden;
  background-image: linear-gradient(to right, white 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  transition: all 0.3s ease;
}

.link-hover:hover {
  background-position: left bottom;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/static/Contact.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,oBAAoB;EACpB,WAAW;EACX,SAAS;EACT,OAAO;EACP,uBAAuB;EACvB,8BAA8B;EAC9B,mCAAmC;AACrC;;AAEA;EACE,oBAAoB;EACpB,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,gBAAgB;EAChB,uEAAuE;EACvE,0BAA0B;EAC1B,iCAAiC;EACjC,6BAA6B;EAC7B,uBAAuB;EACvB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;EAChC,YAAY;AACd","sourcesContent":["@charset \"UTF-8\";\r\n\r\n.custom-underline {\r\n  position: relative;\r\n  display: inline-block;\r\n}\r\n\r\n.footer {\r\n  height: 70vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n}\r\n\r\n.custom-underline::after {\r\n  content: '';\r\n  position: absolute;\r\n  width: 100%;\r\n  transform: scaleX(0);\r\n  height: 2px;\r\n  bottom: 0;\r\n  left: 0;\r\n  background-color: white;\r\n  transform-origin: bottom right;\r\n  transition: transform 0.3s ease-out;\r\n}\r\n\r\n.custom-underline:hover::after {\r\n  transform: scaleX(1);\r\n  transform-origin: bottom left;\r\n}\r\n\r\n.link-hover {\r\n  position: relative;\r\n  display: inline-block;\r\n  overflow: hidden;\r\n  background-image: linear-gradient(to right, white 50%, transparent 50%);\r\n  background-size: 200% 100%;\r\n  background-position: right bottom;\r\n  background-color: transparent;\r\n  border: 2px solid white;\r\n  color: white;\r\n  transition: all 0.3s ease;\r\n}\r\n\r\n.link-hover:hover {\r\n  background-position: left bottom;\r\n  color: black;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
