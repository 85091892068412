import React, { useEffect, useRef } from 'react';

type ColorKey = 'black' | 'white' | 'orange';

interface ExperienceBoxProps {
  logo?: React.ReactNode;
  companyName: string;
  startDate: string;
  endDate?: string;
  jobTitles: {
    title: string;
    startDate: string;
    endDate?: string;
    description: string;
  }[];
  color?: ColorKey;
}

const colorClasses: { [key in ColorKey]: string } = {
  black: 'text-black bg-black',
  white: 'text-white bg-white',
  orange: 'text-orange-500 bg-orange-500',
};

const ExperienceBox: React.FC<ExperienceBoxProps> = ({
  logo,
  companyName,
  startDate,
  endDate,
  jobTitles,
  color = 'black',
}) => {
  const colorClass = colorClasses[color];
  const textColor = colorClass.split(' ')[0];
  const bgColor = colorClass.split(' ')[1];

  const formatDateRange = (start: string, end?: string) => {
    if (end) {
      return `${start} - ${end}`;
    }
    return `${start} - Present`;
  };

  const numberOfDots = (description: string) => {
    const lines = Math.ceil(description.length / 30);
    return Math.max(4, lines);
  };

  const splitDescriptionIntoLines = (description: string) => {
    return description.split('\n');
  };

  const typingRef = useRef<HTMLDivElement>(null);
  const dotRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio > 0) {
          animateDots();
          // Optionally, disconnect observer after the first animation
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    const currentElement = typingRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  const animateDots = () => {
    const dots = dotRefs.current;
    const delay = 200; // Delay between each dot animation in milliseconds
    let currentDelay = 0;

    dots.forEach((dot, index) => {
      if (dot) {
        setTimeout(() => {
          dot.style.opacity = '1';
        }, currentDelay);
        currentDelay += delay;
      }
    });
  };

  return (
    <div className={`flex items-start ${textColor} mb-6`} ref={typingRef}>
      <div className="flex flex-col items-center mr-4">
        <div
          className="w-16 h-16 bg-gray-300 rounded-full flex items-center justify-center mb-2 overflow-hidden"
        >
          {logo}
        </div>
        {jobTitles.map((job, index) => (
          <React.Fragment key={index}>
            <div
              ref={(el) => (dotRefs.current[index * (numberOfDots(job.description) + 2)] = el)}
              className={`w-6 h-6 ${bgColor} rounded-full my-2 opacity-0 transition-opacity duration-300`}
            />
            {Array.from({ length: numberOfDots(job.description) }, (_, dotIndex) => (
              <div
                key={dotIndex}
                ref={(el) =>
                  (dotRefs.current[index * (numberOfDots(job.description) + 2) + dotIndex + 1] = el)
                }
                className={`w-2 h-2 ${bgColor} rounded-full my-1 opacity-0 transition-opacity duration-300`}
              />
            ))}
            {index === jobTitles.length - 1 && (
              <div
                ref={(el) =>
                  (dotRefs.current[index * (numberOfDots(job.description) + 2) + numberOfDots(job.description) + 1] =
                    el)
                }
                className={`w-6 h-6 ${bgColor} rounded-full my-2 opacity-0 transition-opacity duration-300`}
              />
            )}
          </React.Fragment>
        ))}
      </div>
      <div>
        <div className="font-bold text-lg mb-2">
          {companyName}
          <span className="text-sm font-normal block">
            {formatDateRange(startDate, endDate)}
          </span>
        </div>
        {jobTitles.map((job, index) => (
          <div key={index} className="mb-4">
            <div className="font-semibold mt-[14%]">
              {job.title}
              <span className="text-sm font-normal block">
                {formatDateRange(job.startDate, job.endDate)}
              </span>
            </div>
            {/* Render job description with handling for newlines */}
            <p className="text-sm">
              {splitDescriptionIntoLines(job.description).map((line, lineIndex, arr) => (
                <React.Fragment key={lineIndex}>
                  {line}
                  {lineIndex < arr.length - 1 && <br />}
                </React.Fragment>
              ))}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExperienceBox;
