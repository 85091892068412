import React, { useEffect, useState } from 'react';
import { useParams } from 'wouter'; // Import useParams hook from wouter for extracting route params
import axios from 'axios';
import ErrorDisplay from '../components/error/ErrorDisplay';
import handleAxiosError from '../components/error/AxiosError';

const ResendConfirmationEmail: React.FC = () => {
  const { userEmail } = useParams<{ userEmail: string }>();
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const [successMessage, setSuccessMessage] = useState<string>('');

  useEffect(() => {
    const resendEmail = async () => {
      if (!userEmail) return; // Check for userEmail validity

      try {
        const response = await axios.get(`/api/resend-confirmation/${userEmail}`);
        setSuccessMessage(response.data.message);
      } catch (error) {
        const processedErrors = handleAxiosError(error);
        setErrors(processedErrors);
        console.error('There was an error:', processedErrors);
      }
    };

    resendEmail();
  }, [userEmail]); // Depend on userEmail to trigger effect

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-full max-w-md space-y-4">
        {successMessage && <div className="text-green-500">{successMessage}</div>}
        <ErrorDisplay errors={errors} />
      </div>
    </div>
  );
};

export default ResendConfirmationEmail;
